import React, { FC, memo, useCallback } from "react";
import { Box, Paper, useTheme } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridLocaleText,
  GridPaginationModel,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { useAppSelector } from "redux/hooks";
import { alpha } from "@mui/material/styles";

export interface TableDataProps {
  rows: any[];
  columns: GridColDef[];
  onRowClick?: (row: GridRowParams) => void;
  loading?: boolean;
  totalPages?: number;
  rowCount?: number;
  currentPage?: number;
  size?: "small" | "medium";
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  columnVisibilityModel?: any;
  toolbar?: React.ReactNode;
  disableColumnMenu?: boolean;
  rowSelection?: boolean;
  disableColumnSorting?: boolean;
  pageSizeOptions?: number[];
  sx?: any;
}

// Custom extension for additional translations
interface ExtendedGridLocaleText extends Partial<GridLocaleText> {
  customNoRowsLabel?: string;
  customNoResultsLabel?: string;
}

const localeTextVietNamese: ExtendedGridLocaleText = {
  noRowsLabel: "Không có dữ liệu",
  customNoResultsLabel: "Không có kết quả",
  customNoRowsLabel: "Không có dữ liệu",
};

const TableData: FC<TableDataProps> = ({
  rows,
  columns,
  onRowClick,
  toolbar,
  loading,
  paginationModel,
  setPaginationModel,
  rowCount = 0,
  disableColumnMenu = true,
  disableColumnSorting = true,
  pageSizeOptions = [5, 10, 15, 20],
  sx,
}) => {
  const theme = useTheme();
  const languageGlobal = useAppSelector((state) => state.account.language);

  const headerBackgroundColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.background.paper;

  const rowBackgroundColor = {
    "& .row-error-status": {
      backgroundColor: alpha(theme.palette.error.light, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.error.light, 0.2),
      },
    },
    "& .row-info-status": {
      backgroundColor: alpha(theme.palette.info.light, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.info.light, 0.2),
      },
    },
    "& .row-success-status": {
      backgroundColor: alpha(theme.palette.success.light, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.success.light, 0.2),
      },
    },
    "& .row-warning-status": {
      backgroundColor: alpha(theme.palette.warning.light, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.warning.light, 0.2),
      },
    },
    "& .row-secondary-status": {
      backgroundColor: alpha(theme.palette.grey[500], 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.grey[500], 0.2),
      },
    },
  };

  const getRowClassName = useCallback(
    (params: GridRowParams) => {
      const { status, unit_used, unit_num } = params.row;

      // Prioritize unit-based warning
      if (unit_num < 1 || unit_num - unit_used < 1) return "row-error-status";
      else if (unit_num - unit_used < 3) return "row-warning-status";

      // Determine status-based row class
      switch (status) {
        case "NGHIHOC":
        case "OVERDUE":
          return "row-error-status";
        case "HOCTHU":
          return "row-info-status";
        case "DAHOC":
        case "PAID":
          return "row-success-status";
        case "HENHOC":
        case "HOI":
        case "PENDING":
          return "row-warning-status";
        default:
          return "";
      }
    },
    [JSON.stringify(rows)] // Use JSON stringified rows to trigger re-render
  );

  return (
    <Box
      component={Paper}
      elevation={8}
      width="100%"
      height="100%"
      sx={{
        ...sx,
        ...rowBackgroundColor,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .MuiDataGrid-root": {
          flexGrow: 1,
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: headerBackgroundColor,
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontSize: 14,
          fontWeight: "bold",
        },
        "& .MuiDataGrid-main": {
          overflow: "hidden",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        disableColumnMenu={disableColumnMenu}
        rowCount={rowCount}
        onRowClick={onRowClick}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={loading}
        getRowClassName={getRowClassName}
        autoHeight
        slots={{
          toolbar: () => toolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
        localeText={
          languageGlobal.language === "VI" ? localeTextVietNamese : undefined
        }
      />
    </Box>
  );
};

export default memo(TableData);
