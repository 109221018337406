import { Formik } from "formik";
import dayjs, { Dayjs } from "dayjs";
import "./styles.scss";
import AttendanceTable from "./AttendanceTable";
import { AttendanceSchema } from "utils/validation";
import { useMutation } from "@tanstack/react-query";
import attendanceApi from "api/attendanceApi";
import { toast } from "react-toastify";
import { IOption } from "custom-fields/SelectField";

// Define the shape of form values
export interface AttendanceFormValues {
  id?: string | number | null;
  location: IOption | null;
  classes: { id: number; label: string } | null;
  teachers: IOption[];
  date: string | Date | Dayjs;
  attendance_student?: any;
}

const initialValues: AttendanceFormValues = {
  id: undefined,
  location: {
    label: "All",
    code: "all",
    id: "all",
  },
  classes: null,
  teachers: [],
  date: dayjs().format("YYYY-MM-DD"),
  attendance_student: {},
};

const Attendance = (): JSX.Element => {
  const registerNewAttendance = useMutation({
    mutationFn: attendanceApi.registerNewAttendance,
    onSuccess: () => {
      toast.success("Điểm danh thành công");
    },
    onError: () => {
      toast.error("Điểm danh thất bại");
    },
  });

  const updateAttendance = useMutation({
    mutationFn: attendanceApi.updateAttendance,
    onSuccess: () => {
      toast.success("Điểm danh thành công");
    },
    onError: () => {
      toast.error("Điểm danh thất bại");
    },
  });

  const handleSubmit = async (values: AttendanceFormValues) => {
    const { attendance_student, classes, date, teachers, id } = values;
    const student = Object.keys(attendance_student);

    const params = {
      attendance_student,
      classes: classes?.id,
      teacher: teachers.map((t) => t.id),
      date: dayjs(date).format("YYYY-MM-DD"),
      student,
      status: "Lớp Học",
    };

    if (!id) registerNewAttendance.mutate(params);
    else updateAttendance.mutate({ id, ...params });
  };

  return (
    <Formik<AttendanceFormValues>
      initialValues={initialValues}
      validationSchema={AttendanceSchema}
      onSubmit={handleSubmit} // Placeholder submit handler
      enableReinitialize
    >
      <AttendanceTable />
    </Formik>
  );
};

export default Attendance;
