import { IRequestStudent } from "../types";
import axiosClient from "./axiosClient";

const studentApi = {
  // Modified API calls to handle FormData
  createStudent: async (formData: FormData) => {
    const url = "student";
    const res = await axiosClient.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  },
  updateStudent: async ({ data, id }: { data: FormData; id: string }) => {
    const url = `student/${id}`;
    const res = await axiosClient.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  },
  getAttendances: async (id: number) => {
    const url = `student/attendances/${id}`;
    const res = await axiosClient.get(url);
    return res.data;
  },
  getAttendanceUnit: async (id: number, start_date: any) => {
    const url = `student/attendance-unit/${id}?start_date=${start_date}`;
    const res = await axiosClient.get(url);
    return res.data;
  },
  getStudents: async (params: IRequestStudent) => {
    const url = `student`;
    const res = await axiosClient.get(url, { params });
    return res.data;
  },
  getStudentById: async (id: number | string) => {
    const url = `student/${id}`;
    const res = await axiosClient.get(url);
    return res.data;
  },
  deleteStudent: async (id: string) => {
    const url = `student/${id}`;
    const res = await axiosClient.delete(url);
    return res.data;
  },
  deleteStudents: async (params: any) => {
    const url = `student/delete`;
    const res = await axiosClient.delete(url, { data: params });
    return res.data;
  },
  deleteFeeById: async (id: string) => {
    const url = `fee/${id}`;
    const res = await axiosClient.delete(url);
    return res.data;
  },
};

export default studentApi;
