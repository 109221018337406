import { useState, useEffect, useMemo } from "react";
import { Grid2 as Grid, Skeleton, LinearProgress, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Formik, FormikHelpers } from "formik";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import studentApi from "api/studentApi";
import { CREATE, STUDENT_STATUS } from "constants/ConstantCommon";
import InputField from "custom-fields/InputField";
import { Fee, IClass, IStudent } from "types";
import { StudentFormSchema } from "utils/validation";
import { useTranslation } from "react-i18next";
import { Header } from "components/Header";
import { STUDENT } from "constants/routes";
import Button from "components/Button";
import StudentInfo from "./StudentInfo";
import Fees, { IFeeFormik } from "./Fees";
import { convertFeesToFeeData } from "utils/convertType";
import useOptions from "hooks/useOptions/useOptions";
import classApi from "api/classApi";
import { cleanObject } from "utils/common";
import { IOption } from "custom-fields/SelectField";

export interface IStudentFormik extends Omit<IStudent, "fees"> {
  fees?: IFeeFormik[];
}

const StudentDetail = (): JSX.Element => {
  const { id } = useParams();
  const locationRoute = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State to track loading and fetching
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  const {
    data: student,
    isLoading: studentIsLoading,
    isFetching: studentIsFetching,
  } = useQuery({
    queryKey: ["student", id],
    queryFn: () => id && id !== CREATE && studentApi.getStudentById(id),
    enabled: !!id && id !== CREATE,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  const { fees } = student ?? {};

  const { GIFT, FEE_STATUS } = useOptions();

  const class_ids: (string | number)[] = [];

  fees?.forEach((f: Fee) =>
    f?.classes?.forEach((c: string | number) => {
      if (typeof c === "string" || typeof c === "number") {
        class_ids.push(c);
      }
    })
  );

  const { data: classes } = useQuery({
    queryKey: ["classes", class_ids],
    queryFn: () => classApi.getClasses({ ids: JSON.stringify(class_ids) }),
    enabled: class_ids?.length > 0,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  const studentFees = useMemo(
    () =>
      fees?.map((fee: Fee) => {
        const { classes: class_ids } = fee ?? {};
        const feeClasses: IClass[] = classes?.content?.filter(
          (classItem: IClass) =>
            class_ids?.some(
              (classId: string | number) => classId === classItem?.id
            )
        );

        const class_name = feeClasses
          ?.map((c: IClass) => c?.class_id)
          .join(", ");

        const gift_name = GIFT.find((g: any) => g.id === fee?.gift)?.label;
        const status_name = FEE_STATUS.find(
          (status: IOption) => status.id === fee?.status
        )?.label;

        return {
          ...fee,
          classes: class_ids,
          class_name,
          gift_name,
          status_name,
        };
      }),
    [fees, classes]
  );

  const [selectedImage, setSelectedImage] = useState<File>();

  const usedUnit = student?.fee?.unit_used ?? 0;

  const initialValues: IStudentFormik = {
    name: student?.name ?? "",
    parents: student?.parents ?? "",
    dob: student?.dob ?? null,
    register_date: student?.register_date ?? null,
    address: student?.address ?? "",
    phone: student?.phone ?? "",
    email: student?.email ?? "",
    facebook: student?.facebook ?? "",
    fees: studentFees ?? [],
    status: student?.status
      ? {
          id: student?.status,
          label: STUDENT_STATUS.get(student?.status),
          code: student?.status,
        }
      : null,
    used_rest: `${usedUnit} / ${
      student?.fee?.unit_num ? student?.fee?.unit_num - usedUnit : 0
    }`,
    avatar: student?.avatar ?? null,
    student_id: student?.student_id ?? 0,
    note: student?.note ?? "",
  };

  const registerNewStudent = useMutation({
    mutationFn: studentApi.createStudent,
    onSuccess: () => {
      toast.success(t("New Student created success"));
      navigate(STUDENT);
    },
    onError: (err: any) => {
      const { data } = err.response;
      toast.error(t(data));
    },
  });

  const updateStudent = useMutation({
    mutationFn: studentApi.updateStudent,
    onSuccess: () => {
      toast.success(t("Update Student Information success"));
      navigate(STUDENT);
    },
    onError: () => {
      toast.error(t("Update Student Information fail"));
    },
  });

  // In the component's loading state calculations
  const isPending = registerNewStudent.isPending || updateStudent.isPending;

  const handleSubmit = async (
    values: IStudentFormik,
    { setSubmitting }: FormikHelpers<IStudentFormik>
  ) => {
    try {
      const { status, fees } = values;

      const class_ids = Array.from(
        new Set<string>(fees?.flatMap((f) => (f?.classes || []).map(String)))
      );
      const fees_data = cleanObject(convertFeesToFeeData(fees));

      const params: any = {
        ...values,
        status: status?.id,
      };

      if (selectedImage) params.avatar = selectedImage;
      else delete params.avatar;

      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null && key !== "fees") {
          formData.append(key, value as any);
        }
      });

      // Append fees data as a JSON string
      formData.append("fees", JSON.stringify(fees_data));
      formData.append("classes", JSON.stringify(class_ids));

      if (id && id !== CREATE) {
        await updateStudent.mutateAsync({ data: formData, id });
      } else {
        await registerNewStudent.mutateAsync(formData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  // Determine if student data is being fetched
  const isLoadingStudent =
    studentIsLoading || (studentIsFetching && isExternalNavigation);

  // Add effect to handle external navigation
  useEffect(() => {
    // Set flag when navigating back to this page
    setIsExternalNavigation(true);

    // Reset flag after initial load
    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [locationRoute.pathname]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={StudentFormSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(props) => {
        const { handleSubmit, handleReset, values, isSubmitting } = props;
        const { fees } = values;

        return (
          <Stack
            p={2}
            spacing={2}
            component="form"
            onSubmit={handleSubmit}
            onReset={handleReset}
            sx={{ position: "relative" }}
          >
            {/* Fetching Progress Indicator */}
            {(studentIsFetching || isSubmitting) && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                }}
              />
            )}

            <Header
              disabled={isSubmitting || studentIsFetching}
              path={[
                {
                  text: t("Student List"),
                  route: STUDENT,
                },
                {
                  text: t("Student"),
                },
              ]}
            />

            <div />
            <StudentInfo
              isLoading={isLoadingStudent}
              disabled={isSubmitting || studentIsFetching}
            />

            <Fees
              disabled={isSubmitting || studentIsFetching}
              fees={fees}
              loading={isLoadingStudent}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />

            <Grid width="100%">
              {isLoadingStudent ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <Field
                  name="note"
                  component={InputField}
                  label={t("Note")}
                  multiline
                  rows={4}
                  disabled={isSubmitting || studentIsFetching}
                />
              )}
            </Grid>

            {/* Button Groups */}
            {!isLoadingStudent && (
              <Box
                gap={2}
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  type="reset"
                  disabled={isSubmitting || isPending || studentIsFetching}
                >
                  {t("Reset")}
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: "none" }}
                  type="submit"
                  disabled={isSubmitting || isPending || studentIsFetching}
                >
                  {t("Save")}
                </Button>
              </Box>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

export default StudentDetail;
