import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from "@tanstack/react-query";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import authRoutes from "routes/route.auth";
import portalRoutes from "routes/route.portal";
import { Suspense } from "react";
import { ATTENDANCE } from "constants/routes";
import MainLayout from "features/components/MainLayout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalProvider } from "components/ModalProvider/ModalProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "configs/i18n";
import { useAppSelector } from "redux/hooks";
import vi from "assets/languages/vi.json";
import "dayjs/locale/vi";
import BasicToastContainer from "components/BasicToastContainer";

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
    },
    mutations: {
      retry: false,
    },
  },
};
const queryClient = new QueryClient(queryClientConfig);

function App() {
  const [theme, colorMode] = useMode();

  const languageGlobal = useAppSelector((state) => state.account.language);
  const language = languageGlobal?.language?.toLowerCase();
  const localeText = language === "vi" ? vi : undefined;

  return (
    <ColorModeContext.Provider value={colorMode}>
      {/* Move StyledEngineProvider to wrap ThemeProvider */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={language}
                localeText={{
                  ...localeText,
                  calendarWeekNumberText: (weekNumber: number) =>
                    `Tuần ${weekNumber}`,
                }}
              >
                <ModalProvider>
                  <BrowserRouter basename="/">
                    <Routes>
                      {/* Root path redirects */}
                      <Route
                        path=""
                        element={<Navigate to={ATTENDANCE} replace />}
                      />
                      <Route
                        path="/"
                        element={<Navigate to={ATTENDANCE} replace />}
                      />
                      <Route
                        path="/portal"
                        element={<Navigate to={ATTENDANCE} replace />}
                      />

                      {/* Authentication Routes */}
                      {authRoutes.map(
                        ({ path, element: Element }: any, index) => {
                          if (!path || !Element) return null;

                          return (
                            <Route
                              key={`auth-${index}`}
                              path={path}
                              element={
                                <Suspense fallback={<CircularProgress />}>
                                  <Element />
                                </Suspense>
                              }
                            />
                          );
                        }
                      )}

                      {/* Portal Routes */}
                      <Route
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <MainLayout />
                          </Suspense>
                        }
                      >
                        {portalRoutes.map(
                          ({ path, element, children, to }: any, index) => {
                            if (!path || !element) return null;
                            const Element = element;
                            return (
                              <Route
                                key={`portal-${index}`}
                                path={path}
                                element={
                                  to ? (
                                    <Navigate to={to} replace />
                                  ) : (
                                    <Suspense fallback={<CircularProgress />}>
                                      <Element />
                                    </Suspense>
                                  )
                                }
                              >
                                {children?.map(
                                  (child: any, childIndex: number) => {
                                    const ChildElement = child.element;
                                    return (
                                      <Route
                                        key={`portal-child-${index}-${childIndex}`}
                                        path={child.path}
                                        element={
                                          <Suspense
                                            fallback={<CircularProgress />}
                                          >
                                            <ChildElement />
                                          </Suspense>
                                        }
                                      />
                                    );
                                  }
                                )}
                              </Route>
                            );
                          }
                        )}
                      </Route>

                      {/* 404 route */}
                      <Route
                        path="*"
                        element={<Navigate to={ATTENDANCE} replace />}
                      />
                    </Routes>
                  </BrowserRouter>
                  <BasicToastContainer />
                </ModalProvider>
              </LocalizationProvider>
            </I18nextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
