import { IRequestClasses } from "types";
import axiosClient from "./axiosClient";

const classApi = {
  registerNewClass: async (params: any) => {
    const url = "class";
    const res = await axiosClient.post(url, params);
    return res.data;
  },
  updateClass: async (params?: any) => {
    const url = `class/${params?.id}`;
    const res = await axiosClient.patch(url, params);
    return res.data;
  },
  getClasses: async (params?: IRequestClasses) => {
    const url = `class`;
    const res = await axiosClient.get(url, { params });
    return res.data;
  },
  getClassById: async (id?: String | number) => {
    const url = `class/${id}`;
    const res = await axiosClient.get(url);
    return res.data;
  },
  deleteClass: async (id: number | string) => {
    const url = `class/${id}`;
    const res = await axiosClient.delete(url);
    return res.data;
  },
};

export default classApi;
